<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Pagos</h4>
            <div class="small text-muted">Administración de todos los pagos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>            
            <b-col lg="8">
              <b-row>    
                <b-col lg="6">
                  <Widget :configWidget="configWidget" reference="indicator_total_amount_payments_today" :hasAccess="configWidget.elements.indicatorTotalAmountPaymentsToday" />
                </b-col>            
                <b-col lg="6">                        
                  <Widget :configWidget="configWidget" reference="indicator_total_payments_today" :hasAccess="configWidget.elements.indicatorTotalPaymentsToday" />
                </b-col>
                <b-col lg="12">                        
                  <Widget :configWidget="configWidget" reference="bar_payments_months" :hasAccess="configWidget.elements.barPaymentsMonths" />
                </b-col>                  
              </b-row>
            </b-col>
            <b-col lg="4">
              <Widget :configWidget="configWidget" reference="list_balance_positive_current_account" :hasAccess="configWidget.elements.listBalancePositiveCurrentAccount" />
              <Widget :configWidget="configWidget" reference="list_balance_negative_current_account" :hasAccess="configWidget.elements.listBalanceNegativeCurrentAccount" />
            </b-col>            
          </b-row>
        </b-col>  
        <b-col lg="3">
          
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openPayments()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Pagos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de todos los pagos ingresados al sistema
              </p>
            </b-list-group-item>
          </b-list-group>              

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  //import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.PAGOS_ERP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.PAGOS_ERP,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalAmountPaymentsToday: true,
            indicatorTotalPaymentsToday: true,
            barPaymentsMonths: true,
            listBalancePositiveCurrentAccount: true,
            listBalanceNegativeCurrentAccount: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalAmountPaymentsToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_amount_payments_today')
      this.configWidget.elements.indicatorTotalPaymentsToday = Helper.hasAccessWidget(this.configWidget, 'indicator_total_payments_today')
      this.configWidget.elements.barPaymentsMonths = Helper.hasAccessWidget(this.configWidget, 'bar_payments_months')
      this.configWidget.elements.listBalancePositiveCurrentAccount = Helper.hasAccessWidget(this.configWidget, 'list_balance_positive_current_account')
      this.configWidget.elements.listBalanceNegativeCurrentAccount = Helper.hasAccessWidget(this.configWidget, 'list_balance_negative_current_account')
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openPayments() {
        this.$router.push({ name: 'PaymentsErpStaffPayments' })
      }, 
    }
  }
</script>

<style>

</style>